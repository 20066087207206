import React from "react";
import { useStaticQuery, graphql } from "gatsby";

// Components
import Home from "../../layouts/home";
import SEO from "../../components/seo/seo";
import HeroChevron from "../../components/hero/hero-chevron";
import ServiceStates from "../../components/service-states/service-states";
import QuickLinks from "../../components/quick-links/quick-links";
import PersonalizationCards from "../../components/card/personalization-cards";

// Heplers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import LargeCards from "../../components/card/large-cards";

const SpanishIndexPage = () => {
  // NOTE: the new chevron hero image file naming convention below uses the device size rather that the image size.
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/homepage/hero-heloc-102324-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
      card1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-personal-credit-card-102324.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card2ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-interest-checking-102324.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      card3ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-smart-start-102324.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard1ImageVar: file(relativePath: { eq: "cards/personalization/thumbnaill-coverdell-092524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2ImageVar: file(relativePath: { eq: "cards/personalization/thumbnail-business-banking-092524.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 550
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const heroChevronData = {
    id: "spanish-home-page-hero",
    ...getHeroImgVariables(pageData),
    altText: "Una pareja con un contratista mirando los planes de remodelación de su casa.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Paz mental con 1% de descuento HELOC"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-btn-1",
            text: "Aplica Ya",
            url: "/es/banca-personal/prestamos-hipotecarios",
            containerClass: "mb-2",
            class: "btn-white"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white",
            text: "Aplican términos y condiciones, sujeto a cambios y aprobación de crédito."
          }
        }
      ]
    }
  };

  const title = "WaFd Bank: Productos y servicios bancarios";

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "WaFd Bank, antes el Banco Washington Federal, le ofrece productos pensando en usted. Nuestros servicios bancarios incluyen cuentas de cheques y de ahorro; hipotecas, préstamos y más."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/homepage/og-heloc-102324.jpg"
      }
    ],
    schema: {}, // TODO: We are missing this
    addSuffix: false
  };

  let personalizationCardData = [
    {
      // CARD SLOT 1
      slotNumber: 1,
      cards: [
        {
          // Default (CD Special)
          cardNumber: 1,
          id: "spanish-homepage-link-card-1",
          imgData: pageData.card1ImageVar.childImageSharp.gatsbyImageData,
          altText: "Mujer pagando en un café con su tarjeta de crédito.",
          heading: "Obtén una tarjeta de crédito con beneficios y hasta un 2% de cashback",
          subheading: null,
          text: "Obtén cashback con cada deslizamiento.",
          linkUrl: "/es/banca-personal/tarjetas-de-credito"
        }
      ]
    },
    {
      // CARD SLOT 2
      slotNumber: 2,
      cards: [
        {
          // Default (Home Equity)
          cardNumber: 1,
          id: "spanish-homepage-link-card-2",
          imgData: pageData.card2ImageVar.childImageSharp.gatsbyImageData,
          altText: "Hombre y mujer sentados en un coche celebrando con las manos en el aire.",
          heading: "Todo lo que quieres en una cuenta de cheques, con intereses",
          subheading: null,
          text: "Obtén intereses en tu cuenta de cheques, además de una gran cantidad de recompensas y beneficios.",
          linkUrl: "/es/banca-personal/interest-rewards-checking"
        }
      ]
    },
    {
      // CARD SLOT 3
      slotNumber: 3,
      cards: [
        {
          // Default (Smart Start)
          cardNumber: 1,
          id: "spanish-homepage-link-card-3",
          imgData: pageData.card3ImageVar.childImageSharp.gatsbyImageData,
          altText: "Pareja junto a un cartel de vendido frente a su nueva casa.",
          heading: "Comprar una casa es posible con Smart Start",
          text: "Ve si calificas y comienza tu viaje hacia la compra de una casa.",
          linkUrl: "/es/banca-personal/prestamos-hipotecarios/hipoteca-smart-start"
        }
      ]
    }
  ];

  const largeCardData = [
    {
      // Default (Card 1)
      cardNumber: 1,
      imgData: pageData.largeCard1ImageVar.childImageSharp.gatsbyImageData,
      altText: "Padre e hija en un sofá mirando el celular.",
      heading:
        "Ahorra para los costos universitarios futuros de tu hijo con una cuenta de ahorros para educación de Coverdell",
      text: "Ayuda a pagar su colegiatura y que comience su vida adulta con el pie derecho con dinero para la colegiatura, libros y más.",
      linkUrl: "/es/banca-personal/cuentas-de-ahorro#coverdell-section",
      btnText: "Aprende más"
    },
    {
      // Default (Card 2)
      cardNumber: 2,
      imgData: pageData.largeCard2ImageVar.childImageSharp.gatsbyImageData,
      altText: "Dueño de empresa y empleado mirando una tableta.",
      heading: "Encuentra un socio de banca de negocios en WaFd Bank",
      text: "Con soporte y herramientas personalizadas, WaFd está aquí para ayudar a que tu negocio tenga éxito.",
      linkUrl: "/es/banca-de-negocios",
      btnText: "Encuentra herramientas y servicios"
    }
  ];

  return (
    <Home>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <QuickLinks />
      <section className="bg-info">
        <PersonalizationCards containerClass="bg-info" cardData={personalizationCardData} />
        <LargeCards containerClass="bg-info" cardData={largeCardData} lang="es" />
        <ServiceStates containerClass="bg-info" />
      </section>
    </Home>
  );
};

export default SpanishIndexPage;
